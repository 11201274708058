import React from 'react';

import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { addThreeDots } from 'helpers/addThreeDots';
import {
  neutralNr100,
  neutralNr20,
  neutralNr40,
  neutralNr50,
  neutralNr80,
  stateErrorRaya
} from 'common/colors';
import DrawerInfoList from 'components/DrawerInfoList';
import { connect } from 'react-redux';

const DrawerWithSearch = ({
  setIsOpen,
  selectedTitle,
  defaultTitle = '',
  isOpen,
  data,
  setSelectedValue,
  selectedValue,
  id,
  searchable = false,
  handleFetchTeritory,
  handleFetchWorkingField,
  searchPlaceHolder = 'cari item...',
  isUpdateData,
  updateDataText,
  isShow = true,
  loanAmount = 0,
  interestLoanInstallment = 0,
  isOpenOtherPurpose = false,
  setIsOpenOtherPurpose = null,
  withoutOther = false
}) => {
  const [selected, setSelected] = React.useState('');

  React.useEffect(() => {
    let selectedTitleFilter = '';

    if (data) {
      if (id === 'jobTitle' || id === 'dailyNormalTransactions')
        selectedTitleFilter = data.filter(title => title.key === selectedTitle);
      else if (id === 'loanPurpose')
        selectedTitleFilter = data.filter(
          title => title.label === selectedTitle
        );
      else
        selectedTitleFilter = data.filter(
          title => title.value === selectedTitle
        );
    }

    setSelected(selectedTitleFilter);
  }, [selectedTitle]);

  const getSelectedTitle = () => {
    if (selectedTitle) {
      if (typeof selectedTitle === 'object') {
        return selectedTitle?.codeDescription;
      } else {
        if (id === 'loanPurpose' && isOpenOtherPurpose) return selectedTitle;
        if (selected[0]?.label) {
          if (!selected[0]?.label.substring(0, 30)) return defaultTitle;
          else
            return `${selected[0]?.label.substring(0, 30)}${addThreeDots(
              selected[0]?.label
            )}`;
        } else {
          if (!selected[0]?.value.substring(0, 30)) return defaultTitle;
          else
            return `${selected[0]?.value.substring(0, 30)}${addThreeDots(
              selected[0]?.value
            )}`;
        }
      }
    } else if (selectedTitle === 0) {
      if (id === 'numberOfDependents')
        return `${selected[0]?.label.substring(0, 30)}${addThreeDots(
          selected[0]?.label
        )}`;
      else return defaultTitle;
    } else return defaultTitle;
  };

  const handleOpen = () => {
    if (id === 'ksbonTenure') setIsOpen(false);
    else setIsOpen(true);
  };

  return (
    <>
      {isShow && (
        <div>
          <Box
            onClick={handleOpen}
            style={{
              border: isUpdateData
                ? '1px solid red'
                : `1px solid ${neutralNr40}`,
              width: '100%',
              height: '55px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: neutralNr20
            }}
          >
            <Typography
              style={{
                fontFamily: 'Lato',
                fontWeight: 400,
                fontSize: '14px',
                color:
                  selectedTitle ||
                  (id === 'numberOfDependents' && selectedTitle === 0)
                    ? neutralNr100
                    : neutralNr50,
                padding: '0 5px',
                marginLeft: '8px'
              }}
            >
              {getSelectedTitle()}
            </Typography>
            {!(id === 'ksbonTenure') && (
              <IconButton>
                <KeyboardArrowDownIcon style={{ fill: neutralNr80 }} />
              </IconButton>
            )}
          </Box>
          <DrawerInfoList
            data={data}
            handleFetchTeritory={handleFetchTeritory}
            handleFetchWorkingField={handleFetchWorkingField}
            id={id}
            interestLoanInstallment={interestLoanInstallment}
            isOpen={isOpen}
            isOpenOtherPurpose={isOpenOtherPurpose}
            listTitle={defaultTitle}
            loanAmount={loanAmount}
            searchPlaceHolder={searchPlaceHolder}
            searchable={searchable}
            selectedValue={selectedValue}
            setIsOpen={setIsOpen}
            setIsOpenOtherPurpose={setIsOpenOtherPurpose}
            setSelectedValue={setSelectedValue}
            withoutOther={withoutOther}
          />
          {isUpdateData && (
            // eslint-disable-next-line react/jsx-no-undef
            <Grid
              alignItems="center"
              container
              direction="row"
              style={{
                fontFamily: 'Lato',
                fontWeight: 400,
                fontSize: 10,
                lineHeight: '16px',
                color: stateErrorRaya,
                marginLeft: '14px',
                marginTop: '3px'
              }}
            >
              <Grid item>
                <img
                  src="/images/assets/warning_update_data.svg"
                  style={{ margin: 'auto', display: 'block' }}
                />
              </Grid>
              <Grid item style={{ marginLeft: '5px' }}>
                {updateDataText}
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

const mapStatesToProps = states => {
  return {
    schemeCode:
      states.loanApply.appInqRes.schemeCode ||
      states.loanApply.loanScheme.schemeCode ||
      states.loanApply.schemeCode
  };
};

export default connect(mapStatesToProps)(DrawerWithSearch);
