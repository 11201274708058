import * as actionTypes from './actionTypes';
import {
  applicationInquiry,
  loading,
  setBankCode,
  setApplicationStatus
} from './loanApply';
import { setError, setIsLogin } from './auth';
import { setLoans } from './history';
import axios from '../../lib/axios/axios';
import axiosGolang from 'lib/axios/axios-golang';
import {
  USERS,
  CUSTOM_URL,
  USERSV2,
  ESIGN_REGIST_URL
} from '../../common/config';

import { SendEventDisbursement, sendException } from 'helpers/analytics';
import { downloadPDF } from 'helpers/downloadFile';
import { getChannelContextCode, isKasbon } from 'helpers/Utility';

import {
  Photo_Not_Matched,
  Nik_And_Photo_Not_Match,
  Photo_Not_Detected,
  Nik_Not_Registered,
  INVALID_DEMOGRAPHIC,
  LIVENESS_FAILED,
  EKYC_FAILED_CONTACT_CODES,
  ERROR_VIDA,
  COMPLETED_VIDA,
  PROCESSING_VIDA,
  DIG_SIGN_COM_1,
  VIDA,
  DIG_SIG_FAIL,
  DIG_SIG_FAIL7,
  PIC_NOT_MATCHED
} from 'common/constants';

export const editApplicationStatus = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/custom/applications/' + auth.applicationId;
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    const bodyReject = {
      applicationDetails: {
        newStatus: param.status,
        refusalReason: param.refusalReason
      }
    };

    const bodyApprove = {
      applicationDetails: {
        newStatus: param.status
      }
    };

    axiosGolang
      .put(
        url,
        param.status === 'USR_REJECT' ? bodyReject : bodyApprove,
        header
      )
      .then(res => {
        dispatch(setApplicationStatus(res.data.data.applicationStatus.cmCode));
        dispatch(setPassiveLivenessImage(''));
        param.push && param.push();
        dispatch(loading(false));
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      });
  };
};

export const submitPlafondAndTenure = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));
    const url =
      USERS +
      '/custom/applications/' +
      auth.applicationId +
      '/updateLoanDetails';
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    const body = {
      selectedPlafond: Number(param.plafond),
      selectedTenor: param.tenure,
      processingFee: param?.feeAmount
    };

    axiosGolang
      .put(url, body, header)
      .then(() => {
        param && param?.push && param?.push();
        dispatch(loading(false));
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      });
  };
};

export const checkBankCode = auth => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/custom/bankcode/' + auth.applicationId;
    const header = {
      headers: {
        Authorization: auth.token
      }
    };
    axiosGolang
      .get(url, header)
      .then(res => {
        dispatch(setBankCode(res.data.data[0].bankCode));
        dispatch(loading(false));
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      });
  };
};

export const fetchPinangDocument = (auth, param) => {
  return dispatch => {
    // dispatch(loading(true))
    const url =
      USERS +
      '/applications/' +
      auth.applicationId +
      '/custom/doctext/' +
      param.docType;
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        const doc = res.data.data;
        if (param.docType === 'DTRM') dispatch(setPinangDoc1(doc));
        if (param.docType === 'DAOF') dispatch(setPinangDoc2(doc));
        if (param.docType === 'DBTL') dispatch(setPinangDoc3(doc));
        if (param.docType === 'DTRR') dispatch(setPinangDoc4(doc));
        // dispatch(loading(false))
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          // dispatch(loading(false))
        } else {
          dispatch(setError('system'));
          // dispatch(loading(false))
        }
      });
  };
};

export const passiveLiveness = (auth, param) => {
  return (dispatch, getState) => {
    param?.verif && dispatch(loading(true));
    const url = `${USERS}/custom/face/liveness`;
    const body = {
      base64Image: param.base64Image
    };

    const header = {
      headers: {
        Authorization: auth.token
      },
      signal: param.signal()
    };

    axiosGolang
      .post(url, body, header)
      .then(res => {
        // Failed Liveness
        if (res.data.data.code === 211153) {
          dispatch(setError(LIVENESS_FAILED));
          SendEventDisbursement({
            action: 'Liveness Failed',
            isSecondApply: getState().loanApply.isSecondApply
          });
          sendException({
            desc: 'Liveness Failed',
            fatal: 'true'
          });
          return;
        }

        // Success Liveness
        if (res.data.data.code === 1043) {
          !param?.verif &&
            dispatch(
              ekycVerification(auth, {
                selfieData: param?.base64Image,
                action: param?.action
              })
            );
          param?.verif && param?.action && param.action();
          return;
        }
      })
      .catch(err => {
        if (err.code === 'ERR_CANCELED') return;

        if (!err.response || err.code === 'ERR_NETWORK') {
          dispatch(setError('connection'));
          param?.verif && dispatch(loading(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('privysys_fail'));
          SendEventDisbursement({
            action: 'Unexpected Error Liveness',
            isSecondApply: getState().loanApply.isSecondApply
          });
          sendException({
            desc: 'Unexpected Error Liveness',
            fatal: 'true'
          });
          return;
        }

        // Passive liveness failed
        if (
          getChannelContextCode(err, 'channel-context') === '211153' ||
          getChannelContextCode(err, 'channel-context') === '1039'
        ) {
          dispatch(setError(LIVENESS_FAILED));
          SendEventDisbursement({
            action: 'Liveness Failed',
            isSecondApply: getState().loanApply.isSecondApply
          });
          sendException({
            desc: 'Liveness Failed',
            fatal: 'true'
          });
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '1007') {
          dispatch(setError('vida_failed'));
          SendEventDisbursement({
            action: 'Vida Failed',
            isSecondApply: getState().loanApply.isSecondApply
          });
          sendException({
            desc: 'Vida Failed',
            fatal: 'true'
          });
          return;
        }

        //Data not found
        if (getChannelContextCode(err, 'channel-context') === '1012') {
          dispatch(setError('system'));
          SendEventDisbursement({
            action: 'Data not found',
            isSecondApply: getState().loanApply.isSecondApply
          });
          sendException({
            desc: 'Data not found',
            fatal: 'true'
          });
          return;
        }

         //Daily limit usage privy
         if (getChannelContextCode(err, 'channel-context') === '44001') {
          dispatch(setError('system'));
          SendEventDisbursement({
            action: 'Daily limit usage privy',
            isSecondApply: getState().loanApply.isSecondApply
          });
          sendException({
            desc: 'Daily limit usage privy',
            fatal: 'true'
          });
          return;
        }
      })
      .finally(() => param?.verif && dispatch(loading(false)));
  };
};

//VIDA
export const ekycVerification = (auth, param) => {
  return (dispatch, getState) => {
    const url = `${USERS}/${CUSTOM_URL}/${
      getState()?.loanApply?.applicationId
    }/ekyc`;

    const body = {
      selfiePhoto: param.selfieData
    };

    if (param?.reKycForVidaESignPurpose) {
      Object.assign(body, {
        reKycForVidaESignPurpose: param.reKycForVidaESignPurpose
      });
    }

    const header = {
      headers: {
        Authorization: getState()?.auth?.oauthToken,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .post(url, body, header)
      .then(res => {
        const statusCode = res?.data?.code;

        if (statusCode === 1000) {
          dispatch(setError('contact-cs'));
          return;
        }

        if (EKYC_FAILED_CONTACT_CODES.includes(statusCode)) {
          dispatch(setError(INVALID_DEMOGRAPHIC));
          return;
        }

        if (statusCode === 5003 || statusCode === 211153) {
          dispatch(setError(Photo_Not_Matched));
          return;
        }

        if (statusCode === 405 || statusCode === 422) {
          dispatch(setError('system'));
          return;
        }

        dispatch(setEkycKey(res?.data?.data?.kycEventId));
        if (param?.reKycForVidaESignPurpose) {
          dispatch(
            ekycCheckingStatus(auth, {
              isReekyc: true,
              action: param.action
            })
          );
        } else {
          dispatch(ekycCheckingStatus(auth));
        }
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      })
      .finally(() => dispatch(loading(false)));
  };
};

export const ekycCheckingStatus = (auth, param) => {
  return (dispatch, getState) => {
    const url = `${USERS}/${CUSTOM_URL}/${auth?.applicationId}/ekyc/status`;

    const header = {
      headers: {
        Authorization: auth?.token,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        const status = res.data.status;
        dispatch(setEkycStatus(status));

        if (status === 'true') {
          if (param?.isReekyc) {
            dispatch(setApplicationStatus('RE_EKYC_COM'));
            SendEventDisbursement({
              action:
                'User Successfully re-EKYC Verification Dukcapil with Vida',
              isSecondApply: getState().loanApply.isSecondApply
            });
            param?.action();
            return;
          } else {
            dispatch(setApplicationStatus('EKYC_COM'));
            SendEventDisbursement({
              action: 'User Successfully EKYC Verification Dukcapil',
              isSecondApply: getState().loanApply.isSecondApply
            });
            return;
          }
        }

        if (status === 'false') {
          //todo : handle to retry selfie again
          dispatch(setError(Nik_And_Photo_Not_Match));
          return;
        }

        if (status === 'inprogress') {
          //todo: Handle loading for a while
          setTimeout(() => dispatch(ekycCheckingStatus(auth)), 3000);
        }
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      });
  };
};

export const eSignRegistration = (auth, param) => {
  return (dispatch, getState) => {
    param?.accept && dispatch(loading(true));
    const url = USERS + ESIGN_REGIST_URL;
    const body1 = {
      applicationId: auth.applicationId,
      file1: {
        fileData: param.fileData1,
        fileName: auth.applicationId + '.jpg'
      },
      file2: {
        fileData: param.fileData2,
        fileName: auth.applicationId + '.jpg'
      }
    };
    const body2 = {
      applicationId: auth.applicationId,
      file1: {
        fileData: '',
        fileName: ''
      },
      file2: {
        fileData: '',
        fileName: ''
      }
    };
    const header = {
      headers: {
        Authorization: auth.token
      }
    };
    axiosGolang
      .post(url, param.accept ? body2 : body1, header)
      .then(res => {
        if (!param.accept) {
          dispatch(setApplicationStatus('EKYC_COM'));
          SendEventDisbursement({
            action: 'User Successfully EKYC Verification Dukcapil',
            isSecondApply: getState().loanApply.isSecondApply
          });
        }
        dispatch(setApplicationStatus('DIG_SIGN_COM'));
        dispatch(setEsignRegData(res.data));
        param.action && param.action();
        // dispatch(loading(false))
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          SendEventDisbursement({
            action: 'Failed Privy',
            isSecondApply: getState().loanApply.isSecondApply
          });
          sendException({
            desc: 'Failed Privy',
            fatal: 'true'
          });
          dispatch(setError('privysys_fail'));
          return;
        }

        // Record is already exist in privy
        if (getChannelContextCode(err, 'channel-context') === '103557') {
          dispatch(fetchEsignRegData(auth, param));
          return;
        }

        if (getChannelContextCode(err, 'channel-context')) {
          SendEventDisbursement({
            action: 'User Failed to Match face on Dukcapil',
            isSecondApply: getState().loanApply.isSecondApply
          });
          sendException({
            desc: 'User Failed to Match face on Dukcapil',
            fatal: 'true'
          });
          if (getChannelContextCode(err, 'channel-context') === '211102') {
            dispatch(setError(Photo_Not_Matched));
            return;
          } else if (
            getChannelContextCode(err, 'channel-context') === '211104'
          ) {
            dispatch(setError(Nik_And_Photo_Not_Match));
            return;
          } else if (
            getChannelContextCode(err, 'channel-context') === '211105'
          ) {
            dispatch(setError(Photo_Not_Detected));
            return;
          } else if (
            getChannelContextCode(err, 'channel-context') === '211106'
          ) {
            dispatch(setError(Nik_Not_Registered));
            return;
          } else {
            SendEventDisbursement({
              action: 'Failed Privy',
              isSecondApply: getState().loanApply.isSecondApply
            });
            sendException({
              desc: 'Failed Privy',
              fatal: 'true'
            });
            dispatch(setError('privysys_fail'));
            return;
          }
        }

        dispatch(setError('system'));
      })
      .finally(() => {
        param?.accept && dispatch(loading(false));
      });
  };
};

export const fetchEsignRegData = (auth, param) => {
  return dispatch => {
    // dispatch(loading(true))
    const url =
      USERS +
      '/' +
      (localStorage.getItem('phonePrincipal') || auth.phoneNo) +
      '/custom/privyregistrations?applicationId=' +
      auth.applicationId;
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axios
      .get(url, header)
      .then(res => {
        !param.accept && dispatch(setApplicationStatus('EKYC_COM'));
        dispatch(setEsignRegData(res.data));
        param.action && param.action();
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const generateOtpSignature = (auth, params) => {
  return dispatch => {
    dispatch(loading(true));
    const url =
      USERS + '/custom/applications/' + auth.applicationId + '/generate-otp';
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        dispatch(setPrivyToken(res.data.token));
        dispatch(loading(false));
        params.action && params.action();
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      });
  };
};

export const verifyOtpSignature = (auth, params) => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    const url =
      USERS + '/custom/applications/' + auth.applicationId + '/verify-otp';

    const header = {
      headers: {
        Authorization: auth.oauthToken
      }
    };

    const body = {
      otp: params.otp,
      token: auth.privyToken,
      isTncAgreement: auth.isTncAgreement
    };

    if (!getState().auth.updateTncAgreement) delete body.isTncAgreement;

    axiosGolang
      .post(url, body, header)
      .then(() => {
        dispatch(loading(false));
        params.action && params.action();
      })
      .catch(err => {
        if (
          (err.response && err.response.status === 400) ||
          getChannelContextCode(err, 'channel-context') === '103557' ||
          getChannelContextCode(err, 'channel-context') === '100879'
        ) {
          params.failed && params.failed(err.response, 'incorrect_otp');
          dispatch(setError('incorrect_otp'));
          dispatch(loading(false));
          return;
        }
        //expired otp
        if (getChannelContextCode(err, 'channel-context') === '103558') {
          params.failed && params.failed(err.response, 'expired-otp');
          dispatch(setError('expired-otp'));
          dispatch(loading(false));
        } else if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      });
  };
};

export const ekycCheckingVendor = (auth, action) => {
  return dispatch => {
    const url = `${USERS}/${CUSTOM_URL}/${auth?.applicationId}/ekyc/active-vendor`;

    const header = {
      headers: {
        Authorization: auth?.token,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        const ekycVendor = res.data.data;
        dispatch(setEkycVendor(ekycVendor));
        action.success && action.success();
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      });
  };
};

export const checkValidatePhotoStatus = (auth, eSignId, param) => {
  return async dispatch => {
    // Variable state
    let lengthEsign = eSignId.length;
    let resultStatus = '';

    // Looping for check result vida
    for (let [index, id] of eSignId.entries()) {
      const callback = (err, result) => {
        resultStatus = result.vidaESignStatus;

        switch (resultStatus) {
          case ERROR_VIDA:
            if (err.toString().includes('Network Error')) {
              dispatch(setError('connection'));
            } else {
              dispatch(setError('system'));
            }
            break;
          case COMPLETED_VIDA:
            dispatch(setApplicationStatus(result.statusAfterDocCreation));
            param && param?.action && param.action();

            break;
          case PROCESSING_VIDA:
            // If result vida is processing in last index, repeat function
            if (lengthEsign - 1 === index) {
              dispatch(checkValidatePhotoStatus(auth, eSignId, param));
            }
            break;
          default:
            break;
        }
      };

      if (resultStatus === ERROR_VIDA || resultStatus === COMPLETED_VIDA) break;

      await dispatch(
        validatePhotoStatus(
          auth,
          {
            eSignId: id,
            loading: param && param?.loading
          },
          callback
        )
      );
    }
  };
};

export const validatePhotoSignature = (auth, param) => {
  return async dispatch => {
    const url =
      USERS + '/custom/applications/' + auth.applicationId + '/validatephoto';
    const header = {
      headers: {
        Authorization: auth.token
      }
    };
    const body = {
      token: auth.privyToken,
      liveImage1: {
        fileData: param.fileData1,
        fileName: auth.applicationId + '.jpg'
      },
      liveImage2: {
        fileData: param.fileData2,
        fileName: auth.applicationId + '.jpg'
      }
    };
    axiosGolang
      .post(url, body, header)
      .then(res => {
        dispatch(setValidatePhotoSignature(res.data));
        const appStatusRes = res.data.data[0].statusAfterDocCreation;
        const eSignVendor = res.data.data[0].eSignVendor;

        if (appStatusRes === DIG_SIGN_COM_1 || eSignVendor === VIDA) {
          const eSignId = res.data.data[0].vidaESignId?.split('|');
          dispatch(checkValidatePhotoStatus(auth, eSignId, param));
        } else {
          dispatch(
            setApplicationStatus(res.data.data[0].statusAfterDocCreation)
          );
        }
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (err.code === 'ERR_NETWORK') {
          dispatch(setError('connection'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          param.fail && param.fail();
          return;
        }
        // 101043
        // The functionality does not exist currently. Inform the help desk.
        if (getChannelContextCode(err, 'channel-context') === '101043') {
          dispatch(setError('contact-cs'));
          return;
        }

        // Disbursement Failure
        if (
          getChannelContextCode(err, 'channel-context') === '211094' ||
          getChannelContextCode(err, 'channel-context') === '200606' ||
          getChannelContextCode(err, 'channel-context') === '100126'
        ) {
          dispatch(setError('loanCreateFail'));
          param.fail && param.fail();
          return;
        }

        // Verification isn't match
        if (getChannelContextCode(err, 'channel-context') === '211057') {
          dispatch(countingTakePicture());
          if (param.selfieCount === 3) {
            dispatch(setError(DIG_SIG_FAIL));
            // dispatch(resetCountingPicture())
            return;
          } else if (param.selfieCount === 7) {
            dispatch(setError(DIG_SIG_FAIL7));
            dispatch(resetCountingPicture());
            return;
          } else {
            dispatch(setError(PIC_NOT_MATCHED));
            param.picFailed && param.picFailed();
            return;
          }
        }
        dispatch(setError('system'));
      });
  };
};

export const validatePhotoStatus = (auth, param, callback) => {
  return async dispatch => {
    param.loading && dispatch(loading(true));

    const url = `${USERS}/${CUSTOM_URL}/${auth?.applicationId}/validatephoto-status/${param.eSignId}`;

    const header = {
      headers: {
        Authorization: auth?.token,
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axiosGolang.get(url, header);

      dispatch(setEsignStatus(res.data.data));

      if (res.data.data) {
        callback('', res.data.data[0]);
      }
    } catch (err) {
      param.loading && dispatch(loading(false));
      callback(err, { vidaESignStatus: ERROR_VIDA });
    }
  };
};

//CREATE LOAN
export const createLoan = (auth, param) => {
  return (dispatch, getState) => {
    const baseUrl = isKasbon(param?.schemeCode) ? USERSV2 : USERS;
    const url =
      baseUrl + '/custom/applications/' + auth.applicationId + '/createloan';
    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      }
    };

    const bodyKasbon = {
      tenorMonths: param?.tenorMonths
    };

    param?.loading && dispatch(loading(true));
    axiosGolang
      .put(url, isKasbon(param?.schemeCode) ? bodyKasbon : null, header)
      .then(res => {
        dispatch(setCreateLoanRes(res.data.data[0]));
        dispatch(setApplicationStatus(res.data.data[0]?.status));
        if (res.data.data[0].status === 'DISB_IN_PROCESS') {
          param.inProcess && param.inProcess();
          return;
        }
        dispatch(loading(false));
        param.action && param.action();
      })
      .catch(err => {
        SendEventDisbursement({
          action: JSON.stringify(err.response),
          isSecondApply: getState().loanApply.isSecondApply
        });
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (
          getChannelContextCode(err, 'channel-context') === null ||
          getChannelContextCode(err, 'channel-context') === '23409' ||
          getChannelContextCode(err, 'channel-context') === '100126' ||
          getChannelContextCode(err, 'channel-context') === '14084' ||
          getChannelContextCode(err, 'channel-context') === '100128'
        ) {
          param.fail && param.fail(err.response);
          return;
        }
        if (
          getChannelContextCode(err, 'channel-context') === '100127' ||
          getChannelContextCode(err, 'channel-context') === '100129'
        ) {
          param.inProcess && param.inProcess();
          dispatch(setApplicationStatus('DISB_IN_PROCESS'));
          dispatch(setError('double_disburse'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '100130') {
          param.inProcess && param.inProcess();
          dispatch(setApplicationStatus('DISB_IN_PROCESS'));
          return;
        }
        dispatch(setError('system'));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const starsRating = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/' + '/custom/applications/' + auth.applicationId;

    const body = {
      applicationDetails: {
        feedback: String(param.feedback),
        remarks: String(param.remaks)
      }
    };

    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .put(url, body, header)
      .then(() => {
        dispatch(loading(false));
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      });
  };
};

export const getLoan = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/custom/loans';

    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        dispatch(setLoans(res.data.data));
        dispatch(loading(false));
        param.action && param.action();
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          dispatch(loading(false));
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      });
  };
};

export const applicationDocuments = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));

    const url = USERS + '/' + 'custom/applications/document-list';
    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        dispatch(setApplicationDocument(res.data.data));
        param.action && param.action();
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }
        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }
        if (getChannelContextCode(err, 'channel-context') === '200606') {
          dispatch(setError('noDocument'));
          param.action && param.action();
          return;
        }
        dispatch(setError('system'));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const downloadAppDocument = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));

    const url =
      USERS +
      '/custom/fileattachment/' +
      auth.applicationId +
      '/' +
      param.fileNo;
    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    };
    axiosGolang
      .get(url, header)
      .then(res => {
        downloadPDF(res.data, res.headers['content-disposition']);
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }
        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }
        dispatch(setError('system'));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const getLoanAsync = (auth, param) => {
  return async dispatch => {
    dispatch(loading(true));

    const url = USERS + '/custom/loans';

    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axiosGolang.get(url, header);

      if (!res.data.data) {
        dispatch(setError('system'));
        return;
      }

      dispatch(setLoans(res.data.data));
      param && param.action();
      dispatch(loading(false));
    } catch (err) {
      if (err.toString().includes('Network Error')) {
        dispatch(setError('connection'));
        dispatch(setIsLogin(false));
        dispatch(loading(false));
      } else {
        dispatch(setError('system'));
        dispatch(setIsLogin(false));
        dispatch(loading(false));
      }
    }
  };
};

export const accountRegistration = (auth, body, params) => {
  return async dispatch => {
    const url =
      USERS +
      '/custom/raya-digital-saving/account-registration/' +
      auth.applicationId;

    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    params?.loading && dispatch(loading(true));
    try {
      const res = await axiosGolang.post(url, body, header);
      if (res.data && res.data.data) {
        if (res.data.data.registrationStatus) {
          dispatch(setRegistrationStatus(res.data.data.registrationStatus));
          if (res.data.data.registrationStatus === 'EXISTING_FLOW') {
            dispatch(
              setRegistrationStatusIncoming(
                res.data.data.registrationStatusIncoming
              )
            );
            params && params.actionExistingFlow && params.actionExistingFlow();
            dispatch(loading(false));
            return res.data;
          }
        }
        if (res.data.data.registrationStatusIncoming)
          dispatch(
            setRegistrationStatusIncoming(
              res.data.data.registrationStatusIncoming
            )
          );
        if (res.data.data.rayaAccountNumber)
          dispatch(setRayaAccountNumber(res.data.data.rayaAccountNumber));
        if (res.data.data.rayaAccountName)
          dispatch(setRayaAccountName(res.data.data.rayaAccountName));
        if (res.data.data.rayaCif) dispatch(setRayaCif(res.data.data.rayaCif));
        if (res.data.data.urlRaya) dispatch(setUrlRaya(res.data.data.urlRaya));
        dispatch(loading(false));
        return res && res.data;
      }
    } catch (err) {
      SendEventDisbursement({
        category: 'Error Account Registration',
        action: JSON.stringify(err.response),
        label: auth.phoneNo
      });
      if (getChannelContextCode(err, 'channel-context') === '2029600') {
        params && params.actionHitFinRes && params.actionHitFinRes();
        return;
      }

      if (
        getChannelContextCode(err, 'channel-context') === 'RDS1100101' ||
        getChannelContextCode(err, 'channel-context') === 'RDS1200101' ||
        getChannelContextCode(err, 'channel-context') === 'RDS1300101' ||
        getChannelContextCode(err, 'channel-context') === 'RDS1400101' ||
        getChannelContextCode(err, 'channel-context') === '11001' ||
        getChannelContextCode(err, 'channel-context') === '12001' ||
        getChannelContextCode(err, 'channel-context') === '12002' ||
        getChannelContextCode(err, 'channel-context') === '13001' ||
        getChannelContextCode(err, 'channel-context') === '14001' ||
        getChannelContextCode(err, 'channel-context') === '1007'
      ) {
        params && params.actionAccountFail && params.actionAccountFail();
        dispatch(loading(false));
        return;
      }

      if (getChannelContextCode(err, 'channel-context') === 'RDS1100102') {
        params &&
          params.actionRegistrationFail &&
          params.actionRegistrationFail();
        dispatch(loading(false));
        return;
      }

      if (!err.response) {
        dispatch(setError('connection'));
        dispatch(loading(false));
        return;
      }

      dispatch(setError('system'));
      dispatch(loading(false));
    }
  };
};

export const setTimer = timer => {
  return {
    type: actionTypes.SET_TIMER,
    timer: timer
  };
};

export const startTimer = () => {
  return {
    type: actionTypes.START_TIMER
  };
};

export const decMinutes = () => {
  return {
    type: actionTypes.DEC_MINUTES
  };
};

export const decSeconds = () => {
  return {
    type: actionTypes.DEC_SECONDS
  };
};

export const stopTimer = () => {
  localStorage.removeItem('approvedTime');
  return {
    type: actionTypes.STOP_TIMER
  };
};

let interval = null;
export const countDown = (time, auth) => {
  const { minutes, seconds } = time;
  return dispatch => {
    clearInterval(interval);
    interval = setInterval(() => {
      if (seconds > 0) {
        dispatch(decSeconds());
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
          dispatch(stopTimer());
          dispatch(applicationInquiry(auth));
        } else {
          dispatch(decMinutes());
          dispatch(decSeconds());
        }
      }
      if (seconds === 1 && minutes !== 0) {
        dispatch(applicationInquiry(auth));
      }
    }, 1000);
  };
};

export const setCreditScoreResult = result => {
  return {
    type: actionTypes.SET_CS_RESULT,
    result: result
  };
};

export const setOffering1 = selected => {
  return {
    type: actionTypes.SET_OFFERING_1,
    offering: selected
  };
};

export const setOffering2 = selected => {
  return {
    type: actionTypes.SET_OFFERING_2,
    offering: selected
  };
};

export const setSelectedOffering = selected => {
  return {
    type: actionTypes.SET_SELECTED_OFFERING,
    offering: selected
  };
};

export const setloanAmountSelected = selected => {
  return {
    type: actionTypes.SET_LOAN_AMOUNT_SELECETED,
    amount: selected
  };
};

export const setPrivyTnC = tnc => {
  return {
    type: actionTypes.SET_PRIVY_TNC,
    tnc: tnc
  };
};

export const setPrivyRegData = data => {
  return {
    type: actionTypes.SET_PRIVY_REG_DATA,
    data: data
  };
};

export const setEsignRegData = data => {
  return {
    type: actionTypes.SET_ESIGN_REG_DATA,
    data: data
  };
};

export const setPinangDoc1 = doc => {
  return {
    type: actionTypes.SET_PINANG_DOC_1,
    doc: doc
  };
};

export const setPinangDoc2 = doc => {
  return {
    type: actionTypes.SET_PINANG_DOC_2,
    doc: doc
  };
};

export const setPinangDoc3 = doc => {
  return {
    type: actionTypes.SET_PINANG_DOC_3,
    doc: doc
  };
};

export const setPinangDoc4 = doc => {
  return {
    type: actionTypes.SET_PINANG_DOC_4,
    doc: doc
  };
};

export const setPrivyToken = token => {
  return {
    type: actionTypes.SET_PRIVY_TOKEN,
    token: token
  };
};

export const setPrivyValidRes = response => {
  return {
    type: actionTypes.SET_PRIVY_VALID_RES,
    res: response
  };
};

export const setCreateLoanRes = response => {
  return {
    type: actionTypes.SET_CREATE_LOAN_RES,
    res: response
  };
};

export const takeSelfiePrivy = (pic1, pic2, status) => {
  return {
    type: actionTypes.TAKE_SELFIE_PRIVY,
    pic1: pic1,
    pic2: pic2,
    status: status
  };
};

export const takeSelfieFinish = (pic1, pic2, status) => {
  return {
    type: actionTypes.TAKE_SELFIE_FINISH,
    pic1: pic1,
    pic2: pic2,
    status: status
  };
};

export const takeSelfieElement = (pic1, pic2, status) => {
  return {
    type: actionTypes.TAKE_SELFIE_ELEMENT,
    pic1: pic1,
    pic2: pic2,
    status: status
  };
};

export const countingTakePicture = () => {
  return {
    type: actionTypes.COUNT_TAKE_PICTURE
  };
};

export const resetCountingPicture = () => {
  return {
    type: actionTypes.RESET_TAKE_PICTURE
  };
};

export const setApplicationDocument = documents => {
  return {
    type: actionTypes.SET_APPLICATION_DOC,
    doc: documents.reverse()
  };
};

export const setPassiveLivenessImage = imageData => {
  return {
    type: actionTypes.SET_PASSIVE_LIVENESS_IMAGE,
    image: imageData
  };
};

export const setPassiveLivenessImageVerif = imageData => {
  return {
    type: actionTypes.SET_PASSIVE_LIVENESS_IMAGE_VERIF,
    image: imageData
  };
};

export const setEkycKey = key => {
  return {
    type: actionTypes.SET_EKYC_KEY,
    data: key
  };
};

export const setEkycStatus = status => {
  return {
    type: actionTypes.SET_EKYC_STATUS,
    data: status
  };
};

export const setRegistrationStatus = registrationStatus => {
  return {
    type: actionTypes.SET_REGISTRATION_STATUS,
    registrationStatus: registrationStatus
  };
};

export const setRegistrationStatusIncoming = registrationStatusIncoming => {
  return {
    type: actionTypes.SET_REGISTRATION_STATUS_INCOMING,
    registrationStatusIncoming: registrationStatusIncoming
  };
};

export const setBriAccountNumber = briAccountNumber => {
  return {
    type: actionTypes.SET_BRI_ACCOUNT_NUMBER,
    briAccountNumber: briAccountNumber
  };
};

export const setRayaAccountNumber = rayaAccountNumber => {
  return {
    type: actionTypes.SET_RAYA_ACCOUNT_NUMBER,
    rayaAccountNumber: rayaAccountNumber
  };
};

export const setRayaAccountName = rayaAccountName => {
  return {
    type: actionTypes.SET_RAYA_ACCOUNT_NAME,
    rayaAccountName: rayaAccountName
  };
};

export const setRayaCif = rayaCif => {
  return {
    type: actionTypes.SET_RAYA_CIF,
    rayaCif: rayaCif
  };
};

export const setUrlRaya = urlRaya => {
  return {
    type: actionTypes.SET_URL_RAYA,
    urlRaya: urlRaya
  };
};

export const setEkycVendor = vendor => {
  return {
    type: actionTypes.SET_EKYC_VENDOR,
    data: vendor
  };
};

export const setValidatePhotoSignature = response => {
  return {
    type: actionTypes.SET_VALIDATE_PHOTO_SIGNATURE,
    res: response
  };
};

export const setEsignStatus = response => {
  return {
    type: actionTypes.SET_ESIGN_STATUS,
    res: response
  };
};
