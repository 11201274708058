import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/Utility';
import { getCookie } from 'helpers/saveToCookies';
import {
  DEVICE_INFORMATION,
  RAYA_APPS_DATA,
  USER_LOGIN_DATA
} from 'common/constants';
import {
  getDecryptJwtToken,
  getDecryptRayaAppsData,
  getDeviceInformation
} from 'helpers/getQueryStringUrl';

/**
 * decrypt token from cookie for use to initial state
 */
const decryptDecodedToken = getDecryptJwtToken(getCookie(USER_LOGIN_DATA));

/**
 * get device information from cookie for use to initial state
 */
const deviceInformation = getDeviceInformation(getCookie(DEVICE_INFORMATION));

/**
 * decrypt raya apps data from cookie for use to initial state
 */
const decryptDecodedDataRayaApps = getDecryptRayaAppsData(
  getCookie(RAYA_APPS_DATA)
);

// eslint-disable-next-line quotes
const { clientId, clientSecret, VTtoken, partnerId, subCode, referralCode } =
  decryptDecodedToken ? decryptDecodedToken : {};

const { deviceId, deviceName, deviceType } = deviceInformation
  ? deviceInformation
  : {};

const { noHpFlexi, noHpRaya, noHpFlexiNew, isRayaAgreement, status, code } =
  decryptDecodedDataRayaApps ? decryptDecodedDataRayaApps : {};

const initialState = {
  appTnC: '',
  splashScreen: '',
  error: '',
  oauthToken: '',
  oauthExpiredTime: '',
  phoneNo: localStorage.getItem('phoneNo') || noHpFlexi || '',
  phonePrincipal: localStorage.getItem('phonePrincipal') || '',
  noHpRaya: localStorage.getItem('noHpRaya') || noHpRaya || '',
  noHpFlexiNew: localStorage.getItem('noHpFlexiNew') || noHpFlexiNew,
  newPhoneNo: '',
  isLogin: false,
  isBriEmployee: false,
  registeredPin: '',
  typedPin: '',
  newPin: '',
  homePath: '/',
  userProfile: '',
  clientId: clientId ? clientId : '',
  clientSec: clientSecret ? clientSecret : '',
  VTtoken: VTtoken ? VTtoken : '',
  partnerId: partnerId ? partnerId : '',
  subCode: subCode ? subCode : '',
  referralCode: referralCode ? referralCode : '',
  deviceId: deviceId ? deviceId : '',
  deviceName: deviceName ? deviceName : '',
  deviceType: deviceType ? deviceType : '',
  isTncAgreement: 'N',
  statusAuthFlowRaya: status || '',
  codeAuthFlowRaya: code || '',
  dataRayaAppsAuthFlow: decryptDecodedDataRayaApps
    ? decryptDecodedDataRayaApps
    : {},
  isRayaAgreement:
    isRayaAgreement !== 'Y' &&
    localStorage.getItem('isRayaAgreement') !== 'true'
      ? false
      : true,
  errorRayaAgreement: false,
  updateTncAgreement: false
};

const setAppTnC = (state, action) => {
  return updateObject(state, { appTnC: action.appTnC });
};

const setSplashScreen = (state, action) => {
  return updateObject(state, { splashScreen: action.data });
};

const setError = (state, action) => {
  return updateObject(state, { error: action.error });
};

const setOauthToken = (state, action) => {
  return updateObject(state, {
    oauthToken: action.token,
    oauthExpiredTime: action.expiredTime,
    isLogin: true
  });
};

const setPhoneNumber = (state, action) => {
  return updateObject(state, { phoneNo: action.phoneNo });
};

const setNewPhoneNo = (state, action) => {
  return updateObject(state, { newPhoneNo: action.phoneNo });
};

const setPhoneNumberRaya = (state, action) => {
  return updateObject(state, { noHpRaya: action.noHpRaya });
};

const setPhoneNumberFlexiNew = (state, action) => {
  return updateObject(state, { noHpFlexiNew: action.noHpFlexiNew });
};

const setUserId = (state, action) => {
  return updateObject(state, { phonePrincipal: action.phonePrincipal });
};

const setRegisteredPin = (state, action) => {
  return updateObject(state, { registeredPin: action.pin });
};

const inputPinLogin = (state, action) => {
  return updateObject(state, {
    typedPin: state.typedPin + action.typedPin
  });
};

const setNewPin = (state, action) => {
  return updateObject(state, { newPin: action.newPin });
};

const delPinLogin = state => {
  return updateObject(state, {
    typedPin: state.typedPin.slice(0, -1)
  });
};

const resetTypedPin = state => updateObject(state, { typedPin: '' });

const setHomePath = (state, action) => {
  return updateObject(state, { homePath: action.path });
};

const setIsLogin = (state, action) => {
  return updateObject(state, { isLogin: action.status });
};

const setUserProfile = (state, action) => {
  return updateObject(state, { userProfile: action.data });
};

const setParameterAccess = (state, action) => {
  return updateObject(state, {
    clientId: action.params.clientId,
    clientSec: action.params.clientSecret,
    VTtoken: action.params.VTtoken,
    partnerId: action.params.partnerId,
    subCode: action.params.subCode || '',
    referralCode: action.params.referralCode || ''
  });
};

const setDeviceInformation = (state, action) => {
  return updateObject(state, {
    deviceId: action.deviceInformation.deviceId,
    deviceName: action.deviceInformation.deviceName,
    deviceType: action.deviceInformation.deviceType
  });
};

const setIsTncAgreement = (state, action) => {
  return updateObject(state, { isTncAgreement: action.isTncAgreement });
};

const setDataRayaApps = (state, action) => {
  return updateObject(state, { dataRayaAppsAuthFlow: action.data });
};

const setStatusAuthFlowRaya = (state, action) => {
  return updateObject(state, {
    statusAuthFlowRaya: action.statusAuthFlowRaya
  });
};

const setCodeAuthFlowRaya = (state, action) => {
  return updateObject(state, {
    setCodeAuthFlowRaya: action.setCodeAuthFlowRaya
  });
};

const setIsRayaAgreement = (state, action) => {
  localStorage.setItem(
    'isRayaAgreement',
    action.isRayaAgreement !== 'Y' ? 'false' : 'true'
  );

  return updateObject(state, {
    isRayaAgreement: action.isRayaAgreement !== 'Y' ? false : true
  });
};

const setErrorRayaAgreement = (state, action) => {
  return updateObject(state, { errorRayaAgreement: action.errorRayaAgreement });
};

const setUpdateTncAgreement = (state, action) => {
  return updateObject(state, { updateTncAgreement: action.updateTncAgreement });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_APP_TNC:
      return setAppTnC(state, action);
    case actionTypes.SET_SPLASH_SCREEN:
      return setSplashScreen(state, action);
    case actionTypes.SET_ERROR:
      return setError(state, action);
    case actionTypes.SET_OAUTH_TOKEN:
      return setOauthToken(state, action);
    case actionTypes.SET_PHONE_NUMBER:
      return setPhoneNumber(state, action);
    case actionTypes.SET_USER_ID:
      return setUserId(state, action);
    case actionTypes.SET_REG_PIN:
      return setRegisteredPin(state, action);
    case actionTypes.SET_NEW_PIN:
      return setNewPin(state, action);
    case actionTypes.TYPING_PIN:
      return inputPinLogin(state, action);
    case actionTypes.DEL_PIN:
      return delPinLogin(state);
    case actionTypes.RESET_TYPED_PIN:
      return resetTypedPin(state);
    case actionTypes.SET_HOME_PATH:
      return setHomePath(state, action);
    case actionTypes.SET_IS_LOGIN:
      return setIsLogin(state, action);
    case actionTypes.SET_PROFILE_DATA:
      return setUserProfile(state, action);
    case actionTypes.SET_PARAMETER_ACCESS:
      return setParameterAccess(state, action);
    case actionTypes.SET_NEW_PHONENO:
      return setNewPhoneNo(state, action);
    case actionTypes.SET_DEVICE_INFORMATION:
      return setDeviceInformation(state, action);
    case actionTypes.SET_IS_TNC_AGREEMENT:
      return setIsTncAgreement(state, action);
    case actionTypes.SET_DATA_RAYA_APPS:
      return setDataRayaApps(state, action);
    case actionTypes.SET_PHONE_NUMBER_RAYA:
      return setPhoneNumberRaya(state, action);
    case actionTypes.SET_PHONE_NUMBER_FLEXI_NEW:
      return setPhoneNumberFlexiNew(state, action);
    case actionTypes.SET_STATUS_AUTH_FLOW_RAYA:
      return setStatusAuthFlowRaya(state, action);
    case actionTypes.SET_CODE_AUTH_FLOW_RAYA:
      return setCodeAuthFlowRaya(state, action);
    case actionTypes.SET_IS_RAYA_AGREEMENT:
      return setIsRayaAgreement(state, action);
    case actionTypes.SET_ERROR_RAYA_AGREEMENT:
      return setErrorRayaAgreement(state, action);
    case actionTypes.SET_UPDATE_TNC_AGREEMENT:
      return setUpdateTncAgreement(state, action);
    default:
      return state;
  }
};

export default reducer;
