import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';

// Material UI Component
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  rootDrawer: {
    flex: '1 1 auto'
  },
  bodyDrawer: {
    flex: '1 1 auto',
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    overflowY: 'auto'
  },
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme?.palette?.common?.white,
    zIndex: 1,
    height: '56px'
  },
  headerTitleCenter: {
    flex: '1 1 auto',
    textAlign: 'center'
  },
  headerTitleLeft: {
    flex: '1 1 auto',
    marginLeft: theme.spacing(2),
    textAlign: 'left'
  },
  actionItem: {
    flex: '0 0 auto'
  },
  buttonAction: {
    borderRadius: '8px',
    color: theme.palette.common.white
  },
  buttonOutlined: {
    borderRadius: '8px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.neutralNr20
  },
  footer: {
    marginTop: theme.spacing(1.5),
    display: 'flex',
    gap: '8px'
  }
});

const DrawerInfo = ({
  classes,
  actionButtonLabel,
  opened,
  setOpened,
  title,
  children,
  onActionHandler,
  closeButton,
  buttonOutlined,
  labelButtonOutlined,
  onActionOutlined,
  titleAlign = 'center',
  disabledActionHandler = false
}) => {
  return (
    <Drawer
      PaperProps={{
        elevation: 0,
        style: {
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          minHeight: '18%',
          maxWidth: '500px',
          maxHeight: 'calc(100% - 24px)',
          margin: '0 auto'
        }
      }}
      anchor="bottom"
      // disableSwipeToOpen
      onClose={() => setOpened('')}
      // onOpen={() => setOpened(opened)}
      open={opened}
    >
      <Grid
        className={classes.rootDrawer}
        container
        direction="column"
        wrap="nowrap"
      >
        {/* HEADER OF DRAWER INFO */}
        <Grid
          alignItems="center"
          className={classes.header}
          component={'header'}
          container
          direction="row"
          item
          justify="space-between"
          wrap="nowrap"
        >
          <Grid
            className={
              titleAlign === 'left'
                ? classes.headerTitleLeft
                : classes.headerTitleCenter
            }
            item
          >
            <Typography color="textPrimary" variant="subtitle1">
              {title}
            </Typography>
          </Grid>
          <Grid className={classes.actionItem} item>
            {closeButton && (
              <IconButton onClick={onActionHandler}>
                <CloseRoundedIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>

        <Grid
          className={classes.bodyDrawer}
          component="section"
          container
          direction="column"
          justify="space-between"
          wrap="nowrap"
        >
          <Grid
            component="article"
            container
            direction="column"
            item
            wrap="nowrap"
          >
            {/* CONTEN OF DRAWER INFO GOES HERE */}
            {children}
          </Grid>
          {actionButtonLabel && (
            <Grid
              className={classes.footer}
              component="footer"
              container
              direction="column"
              item
            >
              {buttonOutlined && (
                <Button
                  className={classes.buttonOutlined}
                  color="secondary"
                  fullWidth
                  onClick={onActionOutlined}
                  variant="contained"
                >
                  {labelButtonOutlined}
                </Button>
              )}
              <Button
                className={classes.buttonAction}
                color="secondary"
                fullWidth
                disabled={disabledActionHandler}
                onClick={onActionHandler}
                variant="contained"
              >
                {actionButtonLabel}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
};

DrawerInfo.propTypes = {
  actionButtonLabel: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  closeButton: PropTypes.bool,
  onActionHandler: PropTypes.func,
  opened: PropTypes.bool,
  setOpened: PropTypes.func
};

DrawerInfo.defaultProps = {
  closeButton: true
};

export default withStyles(styles)(DrawerInfo);
