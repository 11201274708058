import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Typography,
  makeStyles
} from '@material-ui/core';
import DrawerList from 'components/DrawerList';
import SearchField from 'components/SearchField';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { COMPANY_NAME_ALTERNATIVE } from 'common/constants';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { neutralNr20, textPrimary } from 'common/colors';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import Input from 'components/Input';

const useStyles = makeStyles({
  formItem: {
    labelWidth: 0,
    '& .MuiOutlinedInput-root': { borderRadius: '8px' }
  }
});

const DrawerInfoList = ({
  isOpen,
  setIsOpen,
  data,
  listTitle,
  setSelectedValue,
  selectedValue,
  handleFetchTeritory,
  handleFetchWorkingField,
  id,
  searchable,
  searchPlaceHolder,
  loanAmount,
  interestLoanInstallment,
  isOpenOtherPurpose,
  setIsOpenOtherPurpose,
  setLoanPurpose,
  setTenure,
  setIsCompanyUpdated,
  withoutOther
}) => {
  const classes = useStyles();

  const [dataList, setDataList] = React.useState(searchable ? data || [] : []);
  const [inputText, setInputText] = React.useState('');

  const inputProperty = {
    otherLoanPurpose: {
      className: classes.formItem,
      id: 'otherLoanPurpose',
      placeholder: 'Masukkan alasan kamu',
      valid: false,
      style: { backgroundColor: neutralNr20, color: textPrimary },
      inputProps: {
        required: true,
        maxLength: 50
      },
      fullWidth: true
    }
  };

  React.useEffect(() => {
    setDataList(data);
  }, [data]);

  React.useEffect(() => {
    if (inputText === '') {
      setDataList(data);
    }
  }, [inputText]);

  const handleCloseDrawer = () => {
    setIsOpen(false);
    setTimeout(() => {
      setDataList(data);
    }, 2000);
  };

  const handleChangeValue = text => {
    const isCompanyNameObjExist = 'companyName' in selectedValue;
    const isFieldOfWorkObjExist = 'fieldOfWork' in selectedValue;
    const isFieldOfBusinessObjExist = 'fieldOfBusiness' in selectedValue;
    const isFieldOfSubBusinessObjExist = 'fieldOfSubBusiness' in selectedValue;
    const isJobTitleObjExist = 'jobTitle' in selectedValue;
    const isDailyNormalTransactionsObjExist =
      'dailyNormalTransactions' in selectedValue;
    const isProvinceOfWorkObjExist = 'provinceOfWork' in selectedValue;
    const isCityOfWorkObjExist = 'cityOfWork' in selectedValue;
    const isDistrictOfWorkObjExist = 'districtOfWork' in selectedValue;
    const isWardOfWorkObjExist = 'wardOfWork' in selectedValue;
    const isTenureObjExist = 'tenure' in selectedValue;
    const isLoanPurposeObjExist = 'loanPurpose' in selectedValue;
    const isEducationObjExist = 'education' in selectedValue;
    const isReligionsObjExist = 'religion' in selectedValue;
    const isProvinceObjExist = 'provinsi' in selectedValue;

    setSelectedValue(prev => {
      if (id === 'fieldOfWork' && isFieldOfWorkObjExist) {
        return {
          ...prev,
          [id]: text.value,
          fieldOfWorkDesc: text.label
        };
      }

      if (id === 'fieldOfBusiness' && isFieldOfBusinessObjExist) {
        return {
          ...prev,
          [id]: text.value,
          fieldOfBusinessDesc: text.label,
          fieldOfSubBusinessDesc: ''
        };
      }

      if (id === 'fieldOfSubBusiness' && isFieldOfSubBusinessObjExist) {
        return {
          ...prev,
          [id]: text.value,
          fieldOfSubBusinessDesc: text.label
        };
      }

      if (id === 'jobTitle' && isJobTitleObjExist) {
        return {
          ...prev,
          [id]: text.key,
          jobTitleDesc: text.value
        };
      }

      if (
        id === 'dailyNormalTransactions' &&
        isDailyNormalTransactionsObjExist
      ) {
        return {
          ...prev,
          [id]: text.key,
          dailyNormalTransactionsDesc: text.value
        };
      }

      if (id === 'provinceOfWork' && isProvinceOfWorkObjExist) {
        return {
          ...prev,
          [id]: text.value,
          provinceOfWorkDesc: text.label
        };
      }

      if (id === 'cityOfWork' && isCityOfWorkObjExist) {
        return {
          ...prev,
          [id]: text.value,
          cityOfWorkDesc: text.label
        };
      }

      if (id === 'districtOfWork' && isDistrictOfWorkObjExist) {
        return {
          ...prev,
          [id]: text.value,
          districtOfWorkDesc: text.label
        };
      }

      if (id === 'wardOfWork' && isWardOfWorkObjExist) {
        return {
          ...prev,
          [id]: text.value,
          wardOfWorkDesc: text.label
        };
      }

      if (id === 'tenure' && isTenureObjExist) {
        setTenure(text.value);
        return {
          ...prev,
          [id]: text.value,
          tenureDesc: text.label
        };
      }

      if (
        id === 'companyName' &&
        isCompanyNameObjExist &&
        text.value !== COMPANY_NAME_ALTERNATIVE
      ) {
        setIsCompanyUpdated('Y');
        return {
          ...prev,
          [id]: text.value,
          companyName2: ''
        };
      }

      if (id === 'loanPurpose' && isLoanPurposeObjExist) {
        if (text.value === 'LNN') {
          setIsOpenOtherPurpose(true);
          return {
            ...prev,
            [id]: ''
          };
        } else {
          setLoanPurpose(text.label);
          return {
            ...prev,
            [id]: text.label
          };
        }
      }

      if (
        id === 'companyName' &&
        isCompanyNameObjExist &&
        text.value !== COMPANY_NAME_ALTERNATIVE
      ) {
        setIsCompanyUpdated('Y');

        return {
          ...prev,
          [id]: text.value,
          companyName2: ''
        };
      }

      if (id === 'education' && isEducationObjExist) {
        return {
          ...prev,
          [id]: text.value,
          educationDesc: text.label
        };
      }

      if (id === 'religion' && isReligionsObjExist) {
        return {
          ...prev,
          [id]: text.value
        };
      }

      if (id === 'desa' && isProvinceObjExist) {
        return {
          ...prev,
          [id]: text.value,
          postalCode: text.postalCode
        };
      }

      return { ...prev, [id]: text.value };
    });

    if (id === 'tenure' && isTenureObjExist) setTenure(text.value);
    if (id === 'loanPurpose' && isLoanPurposeObjExist)
      setLoanPurpose(text.value);

    handleFetchTeritory && handleFetchTeritory(id, text.value);
    handleFetchWorkingField && handleFetchWorkingField(id, text.value);

    /**
     * handle close drawer after choose the data
     */
    if (id === 'loanPurpose' && isLoanPurposeObjExist && text.value === 'LNN')
      return;
    else handleCloseDrawer();
  };

  const handleBackChooseOtherPurpose = () => {
    setIsOpenOtherPurpose(false);
    setSelectedValue(prev => {
      return {
        ...prev,
        [id]: ''
      };
    });
    setLoanPurpose('');
  };

  const listDrawer = () => (
    <div
      style={{
        backgroundColor: 'white',
        padding: '8px 16px 40px 16px'
      }}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography
          style={{
            fontFamily: 'Lato',
            fontWeight: 700,
            fontSize: '18px',
            marginTop: id === 'tenure' ? '16px' : '0px'
          }}
        >
          {listTitle}
          {id === 'tenure' && <Typography>termasuk bunga flat 1.5%</Typography>}
        </Typography>

        <IconButton
          onClick={() => {
            handleCloseDrawer();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {searchable ? (
        <SearchField
          dataList={dataList}
          placeholder={searchPlaceHolder}
          setDataList={setDataList}
          setInputText={setInputText}
          withoutOther={withoutOther}
        />
      ) : null}
      <DrawerList
        data={searchable ? dataList : data}
        handleChangeValue={handleChangeValue}
        id={id}
        interestLoanInstallment={interestLoanInstallment}
        loanAmount={loanAmount}
      />
    </div>
  );

  const chooseOtherPurpose = () => {
    return (
      <>
        <Grid container direction="column" wrap="nowrap">
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            wrap="nowrap"
          >
            <Grid item>
              <IconButton onClick={handleBackChooseOtherPurpose}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography color="textPrimary" variant="subtitle1">
                Lainnya
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column" style={{ padding: '16px' }}>
          <Grid item style={{ marginBottom: '16px' }}>
            <Typography variant="body1">
              Alasan lainnya kamu ajukan pinjaman.
            </Typography>
          </Grid>
          <Grid item style={{ marginBottom: '24px' }}>
            <Input
              {...inputProperty.otherLoanPurpose}
              label="Masukkan Tujuan Pinjaman Lainnya"
              onChange={e => {
                setSelectedValue(prev => {
                  return {
                    ...prev,
                    [id]: e.target.value
                  };
                });
              }}
              value={selectedValue?.loanPurpose}
            />
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              disabled={
                selectedValue?.loanPurpose.length < 3 ||
                selectedValue?.loanPurpose.length > 50
              }
              fullWidth
              onClick={() => {
                handleCloseDrawer();
                setLoanPurpose(selectedValue?.loanPurpose);
              }}
              variant="contained"
            >
              Lanjutkan
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <div>
      <Drawer
        PaperProps={{
          elevation: 0,
          style: {
            maxWidth: '500px',
            maxHeight: 'calc(100% - 24px)',
            margin: '0 auto',
            borderRadius: '16px 16px 0px 0px',
            height: 'auto'
          }
        }}
        anchor="bottom"
        open={isOpen}
      >
        {!isOpenOtherPurpose ? listDrawer() : chooseOtherPurpose()}
      </Drawer>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setLoanPurpose: val => {
      dispatch(actions.setLoanPurpose(val));
    },
    setTenure: val => {
      dispatch(actions.setTenure(val));
    },
    setIsCompanyUpdated: value => {
      dispatch(actions.setIsCompanyUpdated(value));
    }
  };
};

export default connect(null, mapDispatchToProps)(DrawerInfoList);
